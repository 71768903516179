import { Component, OnInit } from '@angular/core';

import { CoreSites } from '@services/sites';
import { CoreDomUtils } from '@services/utils/dom';
import { CoreNavigator } from '@services/navigator';
import {CoreUser, CoreUserProfile} from "@features/user/services/user";
import {Translate} from "@singletons";

export enum DataRequestType {
    Export = 1,
    Delete = 2
}

/**
 * Page that displays info about a user.
 */
@Component({
    selector: 'page-core-user-data-request',
    templateUrl: 'data-request.html',
})
export class CoreUserDataRequestPage implements OnInit {

    protected userId!: number;
    protected siteId: string;
    public type!: DataRequestType;
    public comments: string = '';

    courseId!: number;
    userLoaded = false;
    user?: CoreUserProfile;
    title?: string;

    dataRequestType = DataRequestType;
    dataRequestPending? :boolean;

    constructor() {
        this.siteId = CoreSites.getCurrentSiteId();
    }

    /**
     * On init.
     *
     * @return Promise resolved when done.
     */
    async ngOnInit(): Promise<void> {
        this.userId = CoreNavigator.getRouteNumberParam('userId') || 0;
        this.courseId = CoreNavigator.getRouteNumberParam('courseId') || 0;
        this.type = CoreNavigator.getRouteNumberParam('type')!;

        this.fetchUser().finally(() => {
            this.userLoaded = true;
        });
    }

    /**
     * Fetches the user data.
     *
     * @return Promise resolved when done.
     */
    async fetchUser(): Promise<void> {
        try {
            const user = await CoreUser.getProfile(this.userId, this.courseId);

            this.user = user;
            this.title = user.fullname;
        } catch (error) {
            CoreDomUtils.showErrorModalDefault(error, 'core.user.errorloaduser', true);
        }
    }

    /**
     * Delete the user data.
     *
     * @return Promise resolved when done.
     */
    async submitRequest(): Promise<void> {
        try {
            this.dataRequestPending = true;

            const site = await CoreSites.getSite(this.siteId);

            const params: DataRequestWSRequest = {
                type: this.type,
                comments: this.comments
            };

            const response = await site.write<DataRequestWSResponse>('local_dca_create_data_request', params);
            if (response && response.success) {
                CoreDomUtils.showAlert(Translate.instant('core.success'), Translate.instant('core.submitdatarequestsuccess'));
                CoreNavigator.back();
            }
            else {
                CoreDomUtils.showAlert(Translate.instant('core.error'), Translate.instant('core.submitdatarequesterror'));
            }

            this.dataRequestPending = false;
        }
        catch (error) {
            CoreDomUtils.showErrorModalDefault(error, 'core.submitdatarequesterror', true);
            this.dataRequestPending = false;
        }
    }
}

type DataRequestWSRequest = {
    type: DataRequestType;
    comments: string;
};

type DataRequestWSResponse = {
    success: boolean;
};
